import React from "react"
import { ImageBackground } from "react-native"
import { Headline, Animate, styled, Grid, useTheme } from "unikit"
import { Link } from "gatsby"

import George from "../images/koe.jpg"

const Box = styled.View(({ theme, bg }) => ({
  display: "flex",
  width: "100%",
  height:
    theme.window && theme.window.width > 1050 ? "100vh" : bg ? "50vh" : "auto",
  minHeight: theme.window && theme.window.width > 1050 ? "100vh" : "50vh",
  backgroundColor: "surface",
  alignItems: "center",
  justifyContent: "center",
}))

const Address = styled.View({
  backgroundColor: "rgba(0,0,0,0.75)",
  backdropFilter: "blur(5px)",
  padding: 30,
  alignItems: "flex-end",
})

const Text = styled.Text(({ theme }) => ({
  fontSize: theme.fontSize.p,
  lineHeight: theme.fontSize.p * 1.4,
  fontWeight: 100,
  color: "rgba(255,255,255,0.7)",
  display: "inline-block",
}))

export default () => {
  const theme = useTheme()
  return (
    <Grid maxRows={theme.window && theme.window.width > 1050 ? 2 : 1} gap={0}>
      <Box bg>
        <Animate
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
            position: "relative",
          }}
        >
          <ImageBackground
            source={George}
            alt="Three George Düsseldorf"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              justifyContent: "flex-end",
              padding: 40,
              opacity: 0.9,
            }}
          >
            <Animate delay={1000}>
              <Address>
                <Headline
                  style={{
                    color: "#FFF",
                    textAlign: "left",
                  }}
                  level={3}
                  animate
                >
                  Spicy Media GmbH
                </Headline>
                <Text>Französische Str. 12</Text>
                <Text>D-10117 Berlin</Text>
              </Address>
            </Animate>
          </ImageBackground>
        </Animate>
      </Box>

      <Box>
        <Headline
          level={2}
          style={{
            color: "dark",
            textAlign: "center",
          }}
          animate
          // animateType="char"
        >
          Get in Touch
        </Headline>
        <p
          style={{
            color: "text",
            textAlign: "center",
            lineHeight: "1rem",
            marginTop: 20,
          }}
        >
          <a href="mailto:hello@spicymedia.io">hello@spicymedia.io</a>
        </p>
        <p
          style={{
            color: "text",
            textAlign: "center",
            lineHeight: "1rem",
            marginTop: 10,
          }}
        >
          <a href="tel:+4921123855215">+49 (0) 211 23855215</a>
        </p>
        <p
          style={{
            color: "text",
            textAlign: "center",
            lineHeight: "1rem",
            marginTop: 10,
          }}
        >
          <Link to="/imprint">Impressum</Link>
        </p>
      </Box>
    </Grid>
  )
}
